<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #tail>
        <ly-button @click="exportAdd" class="m-l1" :loading="daoLoading">
          导出
        </ly-button>
      </template>
    </ly-searchList>

    <div class="m-t1 tableBox">
      <ly-table :columns="columns" ref="tableRef" size="small" rowKey="id" :data="data" :loading="loading" >
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #remark="{ record }">
          <div class="text">{{ record.remark }}</div>
        </template>
      </ly-table>
    </div>

  </div>
</template>
  
  <script setup>
import { onMounted, ref } from 'vue'
import { iptData, columns } from './profitReport.js'
import { vehicleProfitPage, vehicleProfitExport } from '@/api/finance/income.js'
import { transitionTimeYandM } from '@/utils/util.js'
import { cloneDeep } from 'lodash-es';


const searchMsg = ref({})
const data = ref([])
const loading = ref(false)
const daoLoading = ref(false)
//导出
const exportAdd = () => {
  daoLoading.value = true
  vehicleProfitExport(searchMsg.value, '利润报表-导出').then(res => {
  }).finally(() => {
    daoLoading.value = false
  })
}
const searchAdd = (e) => {
  searchMsg.value.year = transitionTimeYandM(e.year).year
  searchMsg.value.month = transitionTimeYandM(e.year).month
  reqStatementVehiclePage()
}
const resetBtn = () => {
  searchMsg.value = {
    year: transitionTimeYandM(new Date().toLocaleDateString()).year,
    month: transitionTimeYandM(new Date().toLocaleDateString()).month,
  }
  reqStatementVehiclePage()
}

const reqStatementVehiclePage = () => {
  loading.value = true
  vehicleProfitPage(searchMsg.value).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  searchMsg.value = {
    year: transitionTimeYandM(new Date().toLocaleDateString()).year,
    month: transitionTimeYandM(new Date().toLocaleDateString()).month,
  }
  reqStatementVehiclePage()
})
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  min-height: calc(100vh - 230px);
}
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  /*将对象作为弹性伸缩盒子模型显示*/
  display: -webkit-box;
  /*限制文本行数*/
  -webkit-line-clamp: 2;
  /*子元素的排列方式*/
  -webkit-box-orient: vertical;
}
</style> 