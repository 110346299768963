import { ref } from 'vue'
import moment from 'moment'
export const iptData = ref([
  {
    type: 'selectTimeYM',
    placeholder: '选择年月',
    value: moment(new Date()),
    prop: 'year',
    width: '10%',
    allowClear:true
  },

])
export const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    slots:{ customRender: 'sort' },
    align: 'center',
    width: '60px',
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    align: 'center',
  },
  {
    title: '人员姓名',
    dataIndex: 'name',
    align: 'center'
  },
  {
    title: '接车数量',
    dataIndex: 'vehicleNum',
    align: 'center'
  },
  {
    title: '主营收入',
    dataIndex: 'contractAmt',
    align: 'center'
  },
  {
    title: '运输成本',
    dataIndex: 'transportCost',
    align: 'center'
  },
  {
    title: '保险成本',
    dataIndex: 'insuranceCost',
    align: 'center'
  },
 
  {
    title: '税费',
    dataIndex: 'invoiceCost',
    align: 'center'
  },
  {
    title: '附加费',
    dataIndex: 'additionalFee',
    align: 'center'
  },
  {
    title: '返款',
    dataIndex: 'refundFee',
    align: 'center'
  },
  {
    title: '车辆毛利',
    dataIndex: 'grossProfit',
    align: 'center'
  },

  {
    title: '发车数量',
    dataIndex: 'departureNum',
    align: 'center'
  },
  {
    title: '交车数量',
    dataIndex: 'deliveryNum',
    align: 'center'
  },
  
])
